import Login from "./components/auth/login";
import Register from "./components/auth/register";

import Header from "./components/header";
import Home from "./components/home";
import  Calendly  from "./components/home/Calendly";
import GoogleCalendar from "./components/home/GoogleCalendar";
import MakMedOrder from "./components/home/MakMedOrder";
import UploadPaperwork from "./components/home/UploadPaperwork";
import HomeQuestion10 from "./components/home/survey10";
import HomeQuestion2 from "./components/home/survey2";
import HomeQuestion3 from "./components/home/survey3";
import HomeQuestion4 from "./components/home/survey4";
import HomeQuestion5 from "./components/home/survey5";
import HomeQuestion6 from "./components/home/survey6";
import HomeQuestion7 from "./components/home/survey7";
import HomeQuestion8 from "./components/home/survey8";

import { AuthProvider } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";

function App() {
  const routesArray = [
    {
      path: "*",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/home2",
      element: <HomeQuestion2 />,
    },
    {
      path: "/home3",
      element: <HomeQuestion3 />,
    },
    {
      path: "/home4",
      element: <HomeQuestion4 />,
    },
    {
      path: "/home5",
      element: <HomeQuestion5 />,
    },
    {
      path: "/home6",
      element: <HomeQuestion6 />,
    },
    {
      path: "/home8",
      element: <HomeQuestion8 />,
    },
    {
      path: "/home10",
      element: <HomeQuestion10 />,
    },
    {
      path: "/mmo",
      element: <MakMedOrder />,
    },
    {
      path: "/uploadimage",
      element: <UploadPaperwork />,
    },
    {
      path: "/calendly",
      element: <Calendly/>,
    },
    {
      path: "/googlecalendar",
      element: <GoogleCalendar/>,
    },
   
  ];
  let routesElement = useRoutes(routesArray);
  return (
    
    <AuthProvider>
      <Header />
      
      
      <div className="w-full h-screen flex flex-col">{routesElement}</div>
    </AuthProvider>
  );
}

export default App;
