import { useState, useEffect } from "react";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { imageDb } from "../../firebase/firebase";
import { v4 } from "uuid";
import { Button, Grid, Link} from "@mui/material";


function Files({id}) {
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const imagesListRef = ref(imageDb, `paperwork/${id}`);
  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(imageDb, `paperwork/${id}/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  return (
    <div >
            {imageUrls.map((url) => {
        // return <img src={url}/>;
        return <div>
          <Link href={url}>RX</Link>
        </div>
      })}
    </div>
  );
}

export default Files;