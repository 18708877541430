import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import BasicModal from "./BasicModal";
import { collection, getCountFromServer, getFirestore, onSnapshot, getDocs, query, where } from '@firebase/firestore';
import {  db } from "../../firebase/firebase";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useCollectionData } from "react-firebase-hooks/firestore";
import ModeIcon from '@mui/icons-material/Mode';
import Badge from 'react-bootstrap/Badge';



const OpenModalButton = ({ row }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button onClick={handleOpen}>
        <ModeIcon></ModeIcon>


      </Button>
      <BasicModal
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        row={row}
      />
    </>
  );
};

const StatusBadge = ({ row }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <h1>
        Example heading <Badge bg="secondary">New</Badge>
      </h1>
    </div>
  );
};



const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'firstName', headerName: 'First Name', width: 100 },
    { field: 'lastname', headerName: 'Last Name', width: 100 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'permission', headerName: 'Role', width: 100 },
    { field: 'street', headerName: 'Address', width: 150 },
    { field: 'city', headerName: 'City', width: 100 },
    { field: 'livingState', headerName: 'State', width: 100 },
    { field: 'zip', headerName: 'Zip', width: 100 },
    { field: 'status', headerName: 'Status', width: 160,
  
    renderCell: params => {

      console.log(params, "PARAMS")
      if (params.value === 0) {
        return <Badge  pill bg="primary">Questionaire Completed</Badge>
      }
      else if (params.value === 1) {
        return <Badge  pill bg="secondary">Insurance Approved</Badge>
      }
      else if (params.value === 2) {
        return <Badge pill bg="warning">Approved for Doctor Chase </Badge>;
      }
      else if (params.value === 3) {
        return <Badge pill bg="info">RX Received</Badge>;
      }
      else if (params.value === 4) {
        return <Badge pill bg="success">Fitting Scheduled</Badge>
      }
      else if (params.value === 5) {
        return <Badge pill bg="success">
        Fitter Completed
      </Badge>
      }}},
  {
    field: "col2",
    headerName: "Details",
    width: 150,
    renderCell: OpenModalButton
  }
];

export default function ImportantTable() {
  const[rows, setRows] = useState([]);
    const empCollectionRef = collection(db, 'users',);
    // const q = query(empCollectionRef, where("fitterID", "==", "Mitchfitter"));    
    const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [docs] = useCollectionData(empCollectionRef)
    console.log(docs)
    useEffect(()=> {
        getUsers()
    }, [])

  const getUsers = async() => {
      const data = await getDocs(empCollectionRef);

      // console.log(ydocs)
      setRows(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
  }







  return (
    <div style={{ height: 800, width: "75%",  marginTop:'30px'}}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}
