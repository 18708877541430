import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'
import { Button, Grid, Link} from "@mui/material";

import { doc, setDoc } from "firebase/firestore"; 
import { imageDb } from "../../firebase/firebase";

import {
    ref,
    uploadBytes,
    getDownloadURL,
    listAll,
    list,
  } from "firebase/storage";

  import { v4 } from "uuid";


function HomeQuestion2(){
    const [street, setStreet] = useState("");
    const [livingState, setLivingState] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const { currentUser } = useAuth();
    const [imageUpload, setImageUpload] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);


    const userRef = doc(db, "users" , currentUser.uid,)

        const uploadFile = () => {
            if (imageUpload == null) return;
            const imageRef = ref(imageDb,`logos/${currentUser.uid}/${imageUpload.name + v4()}`);
            uploadBytes(imageRef, imageUpload).then((snapshot) => {
              getDownloadURL(snapshot.ref).then((url) => {
                setImageUrls((prev) => [...prev, url]);
              });
            });
        }
    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     

        

        await setDoc(userRef, {
            street, 
            livingState,
            city, 
            zip
          }, { merge: true });

       
        window.location.href="/home3"

      };

      const goBack = () => {
        window.location.href="/home"

    }
    
   
    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-full h-screen flex self-center place-content-center place-items-center">
                <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Please Enter Your Address</h3>
                        </div>

                    </div>
                    <form
                        onSubmit={saveAnswer}
                        className="space-y-4"
                    >
                        <div>
                            <label className="text-sm text-gray-600 font-bold">
                                Street
                            </label>
                            <input
                                type="text"
                               
                                required
                                value={street} onChange={(e) => { setStreet(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <div>
                            <label className="text-sm text-gray-600 font-bold">
                                City
                            </label>
                            <input
                               
                                type="text"
                                autoComplete='new-password'
                                required
                                value={city} onChange={(e) => { setCity(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                            <label className="text-sm text-gray-600 font-bold">
                                State
                            </label>
                            <input
                               
                                type="text"
                                autoComplete='new-password'
                                required
                                value={livingState} onChange={(e) => { setLivingState(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <div>
                            <label className="text-sm text-gray-600 font-bold">
                                Zip Code
                            </label>
                            <input
                                
                                type="text"
                                autoComplete='off'
                                required
                                value={zip} onChange={(e) => { setZip(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div >
                            <input
                                type="file"
                                onChange={(event) => {
                                setImageUpload(event.target.files[0]);
                                }}
                            />
                            <Button variant="contained" sx={{mt: 1}} color="primary" onClick={uploadFile}>Upload File</Button>
                            {/* {imageUrls.map((url) => {
                                // return <img src={url}/>;
                                return <div>
                                <Link href={url}>Fitter Paperwork</Link>
                                </div>
                            })} */}
                            </div>

                        <button
                            onClick={goBack}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Go Back
                            
                        </button>

                        <button
                            type="submit"
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Next Step
                            
                        </button>
                        
                    </form>
                </div>
            </main>
        </>
    )
}
export default HomeQuestion2;