import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore, onSnapshot, getDocs } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
// import ModeIcon from '@mui/icons-material/Mode';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

// const rows = [
//     { id: 1, col1: 'Hello', col2: 'World' },
//     { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
//     { id: 3, col1: 'MUI', col2: 'is Amazing' },
//   ];

  

  

function HomeQuestion8(){
    const[rows, setRows] = useState([]);
    const empCollectionRef = collection(db, 'users',);
    const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const columns = [
    { field: 'firstName', headerName: 'First Name', width: 100 },
    { field: 'lastName', headerName: 'Last Name', width: 100 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'permission', headerName: 'Role', width: 100 },
    { field: 'street', headerName: 'Address', width: 150 },
    { field: 'city', headerName: 'City', width: 100 },
    { field: 'livingState', headerName: 'State', width: 100 },
    { field: 'zip', headerName: 'Zip', width: 100 },
    {
        field: "action",
        headerName: "Action",
        sortable: false,
        renderCell: (params) => {
          const onClick = (e) => {
            // Enter logic to pass data to modal here
          };
    
          return <Button onClick={handleOpen}>
          <ModeIcon />
          
          </Button>;
        }
      },
  ];
    
    

    const [docs] = useCollectionData(empCollectionRef)
    console.log(docs)
    useEffect(()=> {
        getUsers()
    }, [])

    const getUsers = async() => {
        const data = await getDocs(empCollectionRef);
        // console.log(ydocs)
        setRows(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      };
      const  goHome = async () => {
        window.location.href="/mmo";
      };

    
    return (
        <div style={{ height: 400, width: '50%' , marginTop:40}}>
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            View Patient Details
          </Typography>
          {/* {table.selectedRow.data.email} */}
          <div>Fitter TestFitter</div>
          <div>fitter@gmail.com</div>
          <div>123 Main Street</div>
          <div>Las Vegas</div>
          <div>NV</div>

          <button >Call Patient</button>
          <button>Text Patient</button>
          {/* <button onClick={window.location.href="/mmo"}>Text Patient</button> */}
          <form action="http://localhost:3000/mmo">
              <input type="submit" value="Place Order" />
          </form>
          <div>-----0-----0-----0-----0-----0-----0-----0-----0----</div>
          {/* 
           */}
        </Box>
      </Modal>
            <DataGrid rows={rows} columns={columns} />
        </div>
    )
}
export default HomeQuestion8;