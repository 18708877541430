import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion5(){
    // const painLocations = ['Inside', 'Outside', 'Front', 'Back'];
    // const painLengths = ['A Few Days or Weeks', 'A Few Months or so', 'For a While Now'];
    // const painActivities = ['All The Time', 'When Walking or Standing', 'When Sitting','Only Occasionally'];
    // const painYoureFeeling = ['Throbbing', 'Aching', 'My Knees Lock Up','My Knee is Stiff'];
    // const mriadministeredyesno = ['Yes', 'No'];
    // const spendalotoftimeyesno = ['Yes', 'No'];
    // const feelUstableYesno = ['Yes', 'No'];
    // const heatOintmentsyesno = ['Yes', 'No'];
    // const arthritisYesNo = ['Yes', 'No'];
    // const mcyesno = ['Yes', 'No'];
    // const kneeinjuryyesno = ['Yes', 'No'];


    const ptquestion1yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion2yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion3yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion4yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion5yesno = ['First Choice', 'Second Choice', 'Third Choice'];

    const [ptquestion1, setPtquestion1] = useState(ptquestion1yesno[0]);
    const [ptquestion2, setPtquestion2] = useState(ptquestion2yesno[0]);
    const [ptquestion3, setPtquestion3] = useState(ptquestion3yesno[0]);
    const [ptquestion4, setPtquestion4] = useState(ptquestion4yesno[0]);
    const [ptquestion5, setPtquestion5] = useState(ptquestion5yesno[0]);


    // const [userPainLocation, setUserPainLocation] = useState(painLocations[0]);
    // const [howLongHaveYouFeltThisPain, setHowLongHaveYouFeltThisPain] = useState(painLengths[0]);
    // const [doYouFeelThePainAllTheTime, setDoYouFeelThePainAllTheTime] = useState(painActivities[0]);
    // const [describeThePainYoureFeeling, setDescribeThePainYoureFeeling] = useState(painYoureFeeling[0]);
    // const [mriAdministered, setMriAdministered] = useState(mriadministeredyesno[0]);
    // const [spendalotoftime, setspendalotoftime] = useState(spendalotoftimeyesno[0]);
    // const [feelUnstable, setFeelUnstable] = useState(feelUstableYesno[0]);
    // const [heatOintments, setHeatOintments] = useState(heatOintmentsyesno[0]);
    // const [arthritis, setArthritis] = useState(heatOintmentsyesno[0]);
    // const [mc, setMc] = useState(mcyesno[0]);
    // const [kneeinjury, setKneeinjury] = useState(kneeinjuryyesno[0]);




    const { currentUser } = useAuth()

    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     


        await setDoc(doc(db, "users" , currentUser.uid, "physicalTherapyAssessment", currentUser.uid), {
            "PT Question 1":ptquestion1,
            "PT Question 2":ptquestion2,
            "PT Question 3":ptquestion3,
            "PT Question 4":ptquestion4,
            "PT Question 5":ptquestion5,
            

          });

        // const docRef = firebase.firestore().collection('users').doc(firebase.auth()?.currentUser?.uid).collection("codes")
        //       docRef.add({
        //         address,
        //         creation: firebase.firestore.FieldValue.serverTimestamp()
        //     })
        window.location.href="/home6"

      };
    
    //   return (
    //     <div className="container">
    //       <h1>What are some of your favorite tunes?</h1>
    //       <form onSubmit={saveAnswer}>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Street' 
    //         value={street}
    //         onChange={(e)=> {setStreet(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your City' 
    //         value={city}
    //         onChange={(e)=> {setCity(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Zip' 
    //         value={zip}
    //         onChange={(e)=> {setZip(e.target.value)}}
    //         ></input>
    //         <button type="submit">Submit to Firebase</button>
    //       </form>
    //     </div>
    //   );
    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-full h-screen flex self-center place-content-center place-items-center">
                <div className="w-150 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">{`Physical Therapy Assessment`}</h3>
                        </div>

                    </div>






                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>PT Question 1</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setPtquestion1(e.target.value)}
                            defaultValue={ptquestion1}
                        >
                            {ptquestion1yesno.map((value1, idx) => (
                            <option key={idx}>{value1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>PT Question 2</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setPtquestion2(e.target.value)}
                            defaultValue={ptquestion2}
                        >
                            {ptquestion2yesno.map((value1, idx) => (
                            <option key={idx}>{value1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>PT Question 3</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setPtquestion3(e.target.value)}
                            defaultValue={ptquestion3}
                        >
                            {ptquestion3yesno.map((value1, idx) => (
                            <option key={idx}>{value1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>PT Question 4</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setPtquestion4(e.target.value)}
                            defaultValue={ptquestion4}
                        >
                            {ptquestion4yesno.map((value1, idx) => (
                            <option key={idx}>{value1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>PT Question 5</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setPtquestion5(e.target.value)}
                            defaultValue={ptquestion5}
                        >
                            {ptquestion5yesno.map((value1, idx) => (
                            <option key={idx}>{value1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>


                    
                    







                   

                        <button
                            type="submit"
                            onClick={saveAnswer}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Next Step
                            
                        </button>
                        
                </div>
            </main>
        </>
    )
}
export default HomeQuestion5;