import React, { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/authContext'
import FirebaseImageUpload from './FirebaseImageUpload'
import Survey10 from './survey10'
import DragDropFiles from './DragDropFiles'
import HomeQuestion8 from './survey8'
import HomeQuestion2 from './survey10'
import Survey1 from './survey1'
import DragDropFiles2 from './DragDropFiles2'
import HomeQuestion11 from './survey11'
import ImportantTable from './survey11'
import BasicModal2 from './BasicModal'
import Calendly from './Calendly'
import { imageDb } from "../../firebase/firebase";
import {
    ref,
    uploadBytes,
    getDownloadURL,
    listAll,
    list,
  } from "firebase/storage";
import { Link } from '@mui/material'
import { firebaseConfig } from '../../firebase/firebase'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';



const Home = () => {
    const { currentUser } = useAuth()
    const [imageUrls, setImageUrls] = useState([]);
    const imagesListRef = ref(imageDb, `logos/${currentUser.uid}`);
    const [user, setUser] = useState(null);

    firebase.initializeApp(firebaseConfig);


    useEffect(() => {
        listAll(imagesListRef).then((response) => {
          response.items.forEach((item) => {
            getDownloadURL(item).then((url) => {
              setImageUrls((prev) => [...prev, url]);
            });
          });
        });
      }, []);








      useEffect(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.exists) {
              setUser(snapshot.data());
              console.log(snapshot.data())
            } else {
              console.log("does not exist");
            }
          });
    }, []);

    console.log(user)

if (user) { 
  return (<>
  <img style={{height:200, width:500, display: 'block', margin:'auto', borderRadius:0,marginBottom:-30, marginTop:40
    }}src={require('./fittedbrace.png')}></img>
     
      {/* <FirebaseImageUpload/> */}
      {/* <img style={{height:400, width:400, display: 'block', margin:'auto', marginBottom:'-60px'
    }}src={ref(imageDb, `logos/${currentUser.uid}`)}></img> */}

      
                  {/* <img style={{height:200, width:350, display: 'block', margin:'auto', borderRadius:0, marginBottom:10, marginTop:40
    }}src={imageUrls[0]}></img>  */}


      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', display: 'flex',
  }}>
  
  {user.accessToken === 4 ? 
       <ImportantTable/>
       :
       <div style={{display:'flex', alignItems: 'center', justifyContent:'center', height:'100vh' }}>
        You do not have access to this portal. Please Logout</div>
  
    
}
</div>
</>)
}
else {
return <div></div>
}


   
}

export default Home