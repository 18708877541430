import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Grid, Link, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import ProgressBar from "./ProgressBar";
import CustomizedSteppers from "./ProgressBar";
import UploadPaperwork from "./UploadPaperwork";
import Files from "./Files";
import { useState } from "react";
import { collection, getCountFromServer, getFirestore, onSnapshot, getDocs, query, where } from '@firebase/firestore';
import {  db } from "../../firebase/firebase";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useCollectionData } from "react-firebase-hooks/firestore";
import { doc, setDoc } from "firebase/firestore"; 



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius:3,
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};



export default function BasicModal({ onOpen, onClose, open, row }) {
  const [age, setAge] = useState('');
  const [dataSource, setDataSource] = useState();
  const empCollectionRef = collection(db, 'users',);

    React.useEffect(()=> {
        getUsers()
    }, [])

  const getUsers = async() => {
      const data = await getDocs(empCollectionRef);

      // console.log(ydocs)
      setDataSource(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
  }

  const RXReceived = async() => {
    const userRef = doc(db, "users" , row.id)
    await setDoc(userRef, {
      'status': 3
    }, { merge: true });
    location.reload();
    
  }

  console.log("DATASOURCE",dataSource)

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const gotoPDF = () => {
    window.location.href=`https://storage.googleapis.com/a-custom-fit-in-motion.appspot.com/${row.id}-PDF.pdf`

}
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Typography align="center"  sx={{mb: 10}}
  
  variant="h3" component="h3">
             Patient Profile
           </Typography>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
           
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            Name
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            Email
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.firstName} {row.lastName}          
            </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.email} 
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
            Address
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" sx={{ fontWeight: 'bold'}} variant="h6" component="h2">
            Phone Number
          </Typography>
            </Grid>
            
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.street}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.phoneNumber}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.city}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
           
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.livingState}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
           
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="body1" component="h2">
            {row.zip}
          </Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
          </Typography>
            </Grid>



            <Grid item xs={6}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                              Files
                    </Typography>
            </Grid>
            <Grid item xs={6}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                      
                    </Typography>
            </Grid>
            <Grid item xs={6}>
                  <Files id={row.email} />
            </Grid>
            
            
            <Grid item xs={6}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold'}}>
                              
                    </Typography>
            </Grid>
            <Grid item xs={6}>
                  {/* <Link href="https://firebasestorage.googleapis.com/v0/b/a-custom-fit-in-motion.appspot.com/o/files%2F68c6440d-3314-4084-8b36-8947b293458e?alt=media&token=c8742fdd-8590-4f97-948b-52451b73bbbe" color="primary">
                          {`Uploaded Document - ${row.firstName} ${row.lastName}`}
                    </Link> */}
            </Grid>
            <Grid item xs={6}>
                  
            </Grid>


            {(row.status === 1 || row.status === 2 ) ? 
            <Grid item xs={6} align="center" sx={{mt: 7}}>
            <Button variant="contained" color="primary" onClick={RXReceived}>Mark RX Received</Button>

            </Grid>
            :
            <Grid item xs={6} align="center" sx={{mt: 7}}>

            </Grid>
}





            <Grid item xs={6} sx={{mt: 7}} align="center">
            <Button variant="contained" color="primary" onClick={gotoPDF}>Create PDF</Button>

            </Grid>
            
            
          
          
   


            
          
          
          
          
          
          
          </Grid>
        {/* <Typography align="center"
  
   variant="h1" component="h1">
              Patient Profile
            </Typography>
            
            <Typography id="transition-modal-description" component="p">
            {row?.firstName}
            </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {row.firstName} 
          </Typography>
          <Typography id="modal-modal-description" variant="h6" component="h2">
            {row.lastName}
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {row.email} 
          </Typography>
          <Typography id="modal-modal-description" variant="h6" component="h2">
            {row.street}
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {row.city} 
          </Typography>
          <Typography id="modal-modal-description" variant="h6" component="h2">
            {row.livingState}
          </Typography>
          <Typography id="modal-modal-description" variant="h6" component="h2">
            {row.zip}
          </Typography>
          <Button title="CLick ME">BUTTON</Button>
          <button >
     {/* <AddBoxIcon  /> */}


<div>
      <CustomizedSteppers style = {{marginTop:10}} status={row.status}/>

      </div>
        </Box>
      
        
      </Modal>
    </div>
  );
}