import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion6(){
    // const painLocations = ['Inside', 'Outside', 'Front', 'Back'];
    // const painLengths = ['A Few Days or Weeks', 'A Few Months or so', 'For a While Now'];
    // const painActivities = ['All The Time', 'When Walking or Standing', 'When Sitting','Only Occasionally'];
    // const painYoureFeeling = ['Throbbing', 'Aching', 'My Knees Lock Up','My Knee is Stiff'];
    // const mriadministeredyesno = ['Yes', 'No'];
    // const spendalotoftimeyesno = ['Yes', 'No'];
    // const feelUstableYesno = ['Yes', 'No'];
    // const heatOintmentsyesno = ['Yes', 'No'];
    // const arthritisYesNo = ['Yes', 'No'];
    // const mcyesno = ['Yes', 'No'];
    // const kneeinjuryyesno = ['Yes', 'No'];


    const ptquestion1yesno = ['Yes', 'No'];
    // const ptquestion2yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion3yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion4yesno = ['First Choice', 'Second Choice', 'Third Choice'];
    const ptquestion5yesno = ['First Choice', 'Second Choice', 'Third Choice'];

    const [physicianFirstName, setPhysicialFirstName] = useState("");
    const [physicianLastName, setPhysicianLastName] = useState("");
    const [dob, setDob] = useState("");
    const [homeZip, setHomeZip] = useState("");
    const [ptquestion1, setPtquestion1] = useState(ptquestion1yesno[0]);
  


    // const [userPainLocation, setUserPainLocation] = useState(painLocations[0]);
    // const [howLongHaveYouFeltThisPain, setHowLongHaveYouFeltThisPain] = useState(painLengths[0]);
    // const [doYouFeelThePainAllTheTime, setDoYouFeelThePainAllTheTime] = useState(painActivities[0]);
    // const [describeThePainYoureFeeling, setDescribeThePainYoureFeeling] = useState(painYoureFeeling[0]);
    // const [mriAdministered, setMriAdministered] = useState(mriadministeredyesno[0]);
    // const [spendalotoftime, setspendalotoftime] = useState(spendalotoftimeyesno[0]);
    // const [feelUnstable, setFeelUnstable] = useState(feelUstableYesno[0]);
    // const [heatOintments, setHeatOintments] = useState(heatOintmentsyesno[0]);
    // const [arthritis, setArthritis] = useState(heatOintmentsyesno[0]);
    // const [mc, setMc] = useState(mcyesno[0]);
    // const [kneeinjury, setKneeinjury] = useState(kneeinjuryyesno[0]);




    const { currentUser } = useAuth()

    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     


        await setDoc(doc(db, "users" , currentUser.uid, "acknowledgement", currentUser.uid), {
            "Physician First Name":physicianFirstName,
            "Physician Last Name":physicianLastName,
            "Physician DOB":dob,
            "Physician Home Zip":homeZip,
            "Agreed to Terms?": ptquestion1
        
            

          });

        // const docRef = firebase.firestore().collection('users').doc(firebase.auth()?.currentUser?.uid).collection("codes")
        //       docRef.add({
        //         address,
        //         creation: firebase.firestore.FieldValue.serverTimestamp()
        //     })
         window.location.href="/uploadlogo"

      };
    
    
    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-150 h-screen flex self-center place-content-center place-items-center">
                <div className="w-150 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">{`Acknowledgement`}</h3>
                        </div>

                    </div>
                    <p><b>Just 1 one more step before we can help you ease your pain.... <br></br>

Please tell us who your primary care physician is, or the doctor that would provide treatment for this pain, <br></br> so we can contact them and finish up some behind the scenes paperwork for you...</b></p>






                        <div>
                            <input
                                type="text"
                               placeholder="Physician First Name"
                                required
                                value={physicianFirstName} onChange={(e) => { setPhysicialFirstName(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                               placeholder="Physician Last Name"
                                required
                                value={physicianLastName} onChange={(e) => { setPhysicianLastName(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                    <p>I, Jackson Rossi, have initiated contact with A Custom Fit in Motion and/or its affiliate network (hereinafter referred to as "FMB"). I authorize the release of any medical or other information necessary for FMB to process and submit my claims. I understand that by accepting this acknowledgment, I am giving authorization for the release of information that may be confidential, privileged and protected by the law. 
I authorize that payments for medical supplies furnished to me by ACFM be paid directly to ACFM. I select ACFM as a supplier for my healthcare needs and give my full authorization to FMB to bill Medicare, Medicaid, Medicare Supplemental and/or other insurer(s) or their agents or assigns on my behalf. I agree that if my insurance company sends me any payments due ACFM, I will forward such payment immediately upon receipt to ACFM. I agree that I may be responsible for payment of the cost of services that my insurance company does not pay. If there are any costs associated with the services being rendered, ACFM will contact me to discuss my options at that time. 
I authorize ACFM and their affiliates to contact me periodically, by phone, email, text, regular mail, or otherwise, to determine my need for supplies, to conduct quality control surveys as required by Medicare or for any general marketing purposes.  I authorize ACFM to obtain medical or other information necessary in order to process my claim(s), including determining eligibility and seeking reimbursement for medical supplies provided.

I understand that ACFM will notify me of the specific provider that will service this item on our welcome call and that I have the right to refuse service from that provider for any reason.</p>

<p><b>By entering your date of birth below and clicking "I agree", you acknowledge and agree to be bound by the terms of this Release of Medical Information and Assignment of Benefits.</b></p>


<div>
                            <input
                                type="text"
                               placeholder="Date of Birth (MM/DD/YYYY)"
                                required
                                value={dob} onChange={(e) => { setDob(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                               placeholder="Home Zip Code"
                                required
                                value={homeZip} onChange={(e) => { setHomeZip(e.target.value) }}
                                className="w-150 mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Do you Agree?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setPtquestion1(e.target.value)}
                            defaultValue={ptquestion1}
                        >
                            {ptquestion1yesno.map((value1, idx) => (
                            <option key={idx}>{value1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>

                        <button
                            type="submit"
                            onClick={saveAnswer}
                            className='w-120 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Submit
                            
                        </button>
                        
                </div>
            </main>
        </>
    )
}
export default HomeQuestion6;