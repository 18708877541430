import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion3(){
    const options = ["Back", "Left Knee", "Right Knee", "Left Shoulder", "Right Shoulder", "Left Ankle", "Right Ankle", "Left Wrist", 'Right Wrist'];
    const [myValue, setMyValue] = useState(options[0]);
    const { currentUser } = useAuth()

    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     

        const userRef = doc(db, "users" , currentUser.uid,)


        // await setDoc(doc(db, "users" , currentUser.uid, "whereDoesItHurt", currentUser.uid), {
        //     "Where does it hurt?":myValue
        //   });

          await setDoc(userRef, {
            "Where does it hurt?":myValue
          }, { merge: true });

        // const docRef = firebase.firestore().collection('users').doc(firebase.auth()?.currentUser?.uid).collection("codes")
        //       docRef.add({
        //         address,
        //         creation: firebase.firestore.FieldValue.serverTimestamp()
        //     })
        window.location.href="/home4"

      };


    const goBack = () => {
        window.location.href="/home2"

    }
    
    //   return (
    //     <div className="container">
    //       <h1>What are some of your favorite tunes?</h1>
    //       <form onSubmit={saveAnswer}>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Street' 
    //         value={street}
    //         onChange={(e)=> {setStreet(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your City' 
    //         value={city}
    //         onChange={(e)=> {setCity(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Zip' 
    //         value={zip}
    //         onChange={(e)=> {setZip(e.target.value)}}
    //         ></input>
    //         <button type="submit">Submit to Firebase</button>
    //       </form>
    //     </div>
    //   );
    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-full h-screen flex self-center place-content-center place-items-center">
                <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Where does it Hurt?</h3>
                        </div>

                    </div>
                    
                    <div class="flex justify-center items-center">
                                        <img src={require('../../Bodylabels.png')} height={800 } width={800}   />
                                        </div>


                                        <div class="flex justify-center items-center">
                                        <select
                                            onChange={(e) => setMyValue(e.target.value)}
                                            defaultValue={myValue}
                                        >
                                            {options.map((option, idx) => (
                                            <option key={idx}>{option}</option>
                                            ))}
                                        </select>
                                        
                                        </div>
                   

                        <button
                            onClick={goBack}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Go Back
                            
                        </button>
                        <button
                            type="submit"
                            onClick={saveAnswer}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Next Step
                            
                        </button>
                        
                </div>
            </main>
        </>
    )
}
export default HomeQuestion3;